/** 
* Typography 
*/

/* Base font settings */
$f_font-family: myriad-pro, sans-serif; 
$f_font-size: 1rem; // 16px
$f_font-weight: 400;
$f_line-height: 1.375; //22px

/* Headings */

/* H1 main */
$f_h1-main-d-size: 54px;
$f_h1-main-d-weight: 700;
$f_h1-main-d-line-height: normal;

$f_h1-main-t-size: 42px;
$f_h1-main-t-weight: 700;
$f_h1-main-t-line-height: normal;

$f_h1-main-m-size: 34px;
$f_h1-main-m-weight: 700;
$f_h1-main-m-line-height: normal;

/* H1 product */
$f_h1-product-d-size: 50px;
$f_h1-product-d-weight: 700;
$f_h1-product-d-line-height: normal;

$f_h1-product-t-size: 38px;
$f_h1-product-t-weight: 700;
$f_h1-product-t-line-height: normal;

$f_h1-product-m-size: 32px;
$f_h1-product-m-weight: 700;
$f_h1-product-m-line-height: normal;

/* H1 other */
$f_h1-other-d-size: 48px;
$f_h1-other-d-weight: 700;
$f_h1-other-d-line-height: normal;

$f_h1-other-t-size: 40px;
$f_h1-other-t-weight: 700;
$f_h1-other-t-line-height: normal;

$f_h1-other-m-size: 32px;
$f_h1-other-m-weight: 700;
$f_h1-other-m-line-height: normal;

/* H2 */
$f_h2-d-size: 36px;
$f_h2-d-weight: 700;
$f_h2-d-line-height: normal;

$f_h2-t-size: 32px;
$f_h2-t-weight: 700;
$f_h2-t-line-height: normal;

$f_h2-m-size: 27px;
$f_h2-m-weight: 700;
$f_h2-m-line-height: normal;

/* H3 */
$f_h3-d-size: 30px;
$f_h3-d-weight: 700;
$f_h3-d-line-height: normal;

$f_h3-t-size: 26px;
$f_h3-t-weight: 700;
$f_h3-t-line-height: normal;

$f_h3-m-size: 23px;
$f_h3-m-weight: 700;
$f_h3-m-line-height: normal;

/* H4 */
$f_h4-d-size: 24px;
$f_h4-d-weight: 700;
$f_h4-d-line-height: normal;

$f_h4-t-size: 22px;
$f_h4-t-weight: 700;
$f_h4-t-line-height: normal;

$f_h4-m-size: 19px;
$f_h4-m-weight: 700;
$f_h4-m-line-height: normal;

/* H5 */
$f_h5-d-size: 21px;
$f_h5-d-weight: 700;
$f_h5-d-line-height: normal;

$f_h5-t-size: 19px;
$f_h5-t-weight: 700;
$f_h5-t-line-height: normal;

$f_h5-m-size: 18px;
$f_h5-m-weight: 700;
$f_h5-m-line-height: normal;

/* H6 */
$f_h6-d-size: 18px;
$f_h6-d-weight: 700;
$f_h6-d-line-height: normal;

$f_h6-t-size: 18px;
$f_h6-t-weight: 700;
$f_h6-t-line-height: normal;

$f_h6-m-size: 17px;
$f_h6-m-weight: 700;
$f_h6-m-line-height: normal;

/* body */
$f_body-d-size: 16px;
$f_body-d-weight: 400;
$f_body-d-line-height: normal;

$f_body-t-size: 15px;
$f_body-t-weight: 400;
$f_body-t-line-height: normal;

$f_body-m-size: 15px;
$f_body-m-weight: 400;
$f_body-m-line-height: normal;

/* body small */
$f_body-small-d-size: 14px;
$f_body-small-d-weight: 400;
$f_body-small-d-line-height: normal;

$f_body-small-t-size: 14px;
$f_body-small-t-weight: 400;
$f_body-small-t-line-height: normal;

$f_body-small-m-size: 12px;
$f_body-small-m-weight: 400;
$f_body-small-m-line-height: normal;

/* body large */
$f_body-large-d-size: 20px;
$f_body-large-d-weight: 400;
$f_body-large-d-line-height: normal;

$f_body-large-t-size: 20px;
$f_body-large-t-weight: 400;
$f_body-large-t-line-height: normal;

$f_body-large-m-size: 20px;
$f_body-large-m-weight: 400;
$f_body-large-m-line-height: normal;

/* Display */
$f_display1-d-size: 60px;
$f_display1-d-weight: 700;
$f_display1-d-line-height: normal;

$f_display1-t-size: 52px;
$f_display1-t-weight: 700;
$f_display1-t-line-height: normal;

$f_display1-m-size: 48px;
$f_display1-m-weight: 700;
$f_display1-m-line-height: normal;

$f_display2-d-size: 30px;
$f_display2-d-weight: 400;
$f_display2-d-line-height: 48px;

$f_display2-t-size: 24px;
$f_display2-t-weight: 400;
$f_display2-t-line-height: 44px;

$f_display2-m-size: 18px;
$f_display2-m-weight: 400;
$f_display2-m-line-height: 27px;

/* Breadcrumbs */
$f_breadcrumbs-d-size: 12px;
$f_breadcrumbs-d-weight: 400;
$f_breadcrumbs-d-line-height: normal;

$f_breadcrumbs-t-size: 12px;
$f_breadcrumbs-t-weight: 400;
$f_breadcrumbs-t-line-height: normal;

$f_breadcrumbs-m-size: 10px;
$f_breadcrumbs-m-weight: 400;
$f_breadcrumbs-m-line-height: normal;

/**
* Buttons
*/
$f_cta-btn-m-size: 18px;
$f_cta-btn-m-weight: 700;
$f_cta-btn-m-line-height: 24px;

$f_cta-btn-s-size: 14px;
$f_cta-btn-s-weight: 700;
$f_cta-btn-s-line-height: 19px;

/** 
* Menu 
*/
$f_menu-d-size: 18px;
$f_menu-d-line-height: 24px;

$f_menu-tm-size: 16px;
$f_menu-tm-line-height: 19px;

/** 
* Colors 
*/
$f_primary: #A79E70;
$f_primary-chroma-plus: #BEAA74;
$f_primary-light: #E5E2D4;
$f_primary-superlight: #F6F5F1;
$f_primary-dark: #938769;

$f_secondary: #383E42;
$f_secondary-dark: #1E2225;
$f_secondary-light: #DCDDDE;
$f_secondary-superlight: #E3E4E5;

$f_gray-0: #FFFFFF;
$f_gray-100: #FDFDFD;
$f_gray-150: #FAFAFA;
$f_gray-200: #F7F7F7;
$f_gray-300: #E3E4E5;
$f_gray-400: #DCDDDE;
$f_gray-500: #C5C6C7;
$f_gray-600: #888888;
$f_gray-700: #383E42;
$f_gray-800: #2D3337;
$f_gray-900: #1E2225;
$f_gray-950: #000000;

$f_image-overlay: #383E42E5;
$f_link: #163AFA;
$f_link-hover: #163AFA;
$f_card-bg: #FFFFFF;
$f_footer-bg: #1E2225;
$f_error: #FF4444;
$f_success: #00C851;

$f_body-bg: #FFFFFF;
$f_bg-primary: #E3E4E5;
$f_bg-secondary: #DCDDDE;
$f_base-color: #383E42;
$f_base-color-inverted: #FDFDFD;
$f_accent: #A79E70;
$f_white: #FFFFFF;
$f_border-color-primary: #C5C6C7;
$f_border-color-secondary: #2D3337;
$f_text-color: #383E42;
$f_text-color-light: #C5C6C7;
$f_text-color-inverted: #F9F9F9;
$f_text-color-inverted-light: #C5C6C7;
$f_table-header-color: #DCDDDE;
$f_table-row-odd-color: #FFFFFF;
$f_tabel-row-even-color: #FDFDFD;

$f_card-bg: $f_gray-0;
$f_card-text: $f_base-color;

/** 
* Containers
*/

$f_container-xl: 1440px;
$f_container-lg: 992px;
$f_container-md: 720px;
$f_container-sm: 540px;

/** 
* Border raidus 
*/

$f_border-radius: 0;
$f_border-radius-sm: 0;
$f_border-radius-lg: 0;
$f_border-radius-xl: 0;
$f_border-radius-circle: 0;
$f_border-width: 1px;
$f_border-width-lg: 2px;

/** 
* Spacers 
*/

$f_spacer-0: 0px;
$f_spacer-1: 1px;
$f_spacer-2: 2px;
$f_spacer-3: 4px;
$f_spacer-4: 8px;
$f_spacer-5: 12px;
$f_spacer-6: 14px;
$f_spacer-7: 18px;
$f_spacer-8: 26px;
$f_spacer-9: 35px;
$f_spacer-10: 45px;
$f_spacer-11: 52px;
$f_spacer-12: 60px;
$f_spacer-13: 68px;
$f_spacer-14: 80px;
$f_spacer-15: 90px;
$f_spacer-16: 100px;
$f_spacer-17: 110px;
$f_spacer-18: 120px;
$f_spacer-19: 130px;
$f_spacer-20: 140px;

/** 
* Icon 
*/

$f_modul-icon-size-D: 100px;
$f_modul-icon-size-T: 90px;
$f_modul-icon-size-M: 72px;

/** 
* Button 
*/

$f_btn-border-radius: 0;
$f_btn-small-border-radius: 0;
$f_btn-padding-x: 20px;
$f_btn-padding-y: 10px;
$f_btn-small-padding-x: 10px;
$f_btn-small-padding-y: 8px;

/** 
* Table 
*/
$f_table-color: #4C4C4C;
$f_table-th-font-weight: 700;

/** 
* Navbar --- Variables are not in Figma
*/
$f_nav-link-font-size: 20px;
$f_nav-link-font-weight: 700;
$f_navbar-light-color: #4c4c4c;
$f_navbar-padding-x: 20px;
$f_navbar-padding-y: 0;
$f_navbar-nav-link-padding-x: $f_spacer-7;
$f_navbar-brand-padding-y: 0;
$f_navbar-brand-margin-end: 42px;
$f_navbar-bg: $f_secondary;

/** 
* Badge
*/
$f_badge-font-size: 14px;
$f_badge-font-weight: 400;
$f_badge-padding-y: $f_spacer-3;
$f_badge-padding-x: $f_spacer-4;
$f_badge-border-radius: 4px;

/** 
* Quote
*/
// $f_quote: '$quote-default'; // Default quote
// $f_quote_name: 'default';
$f_quote: $quote-special; // Special quote
$f_quote_name: 'special';

/** 
* Cards
*/
$f_card-spacer-y: $f_spacer-7;
$f_card-spacer-x: $f_spacer-7;
$f_card-title-color: $f_text-color;
$f_card-subtitle-color: $f_text-color;
$f_card-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.30);
$f_card-border-radius: 4px;
$f_card-title-spacer-y: $f_spacer-7;