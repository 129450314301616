.wkb-testimonials,
.wkb-cards,
.wkb-posts {
  @include media-breakpoint-down(md) {
    overflow: hidden;
  }
  .cta {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .archive-url {
    margin-top: 56px;
  }
  .badge {
    border-radius: 0;
    margin-bottom: 0
  }
  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 32px;
  }
  .owl-stage {
    margin-left: -45px;
    display: flex;

    &-outer {
      @include media-breakpoint-down(md) {
        overflow: visible;
      }
    }
  }
  .owl-item {
    display: flex;
		flex: 1 0 auto;
  }
  .meta-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .card-categories,
    .updated {
      margin-bottom: 18px;
    }
  }
  @include media-breakpoint-up(md) {
    .owl-carousel.owl-loaded {
      position: relative;
  
      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 20%;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 30%, rgb(255, 255, 255) 100%);
        top: 0;
        right: 0;
      }
    }
  }
  .controles-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 56px;
    // align-items: center;
    margin-top: 56px;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
    }
    .owl-dot {
      width: 32px;
      height: 8px;
      background: $primary;
      margin: 0 2px;
      border-radius: 4px;
      border: none;
      transition: .3s;
  
      &.active {
        width: 96px;
      }
      
      &:not(.active) {
        background: $gray-500;
      }
    }
  }
  .hide-image {
    article .card img {
      display: none;
    }
  }
}