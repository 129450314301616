/* Vertical / Horizontal / Both centering */
@mixin centerer($type: 'horizontal', $position: absolute) {
  position: $position;

  @if $type == 'horizontal' {
    @include set-transform(translateX(-50%));
    left: 50%;
  }

  @if $type == 'vertical' {
    @include set-transform(translateY(-50%));
    top: 50%;
  }

  @if $type == 'both' {
    @include set-transform(translate(-50%, -50%));
    top: 50%;
    left: 50%;
  }
}

/* Round corners */
@mixin rounded($args...) {
  border-radius: $args;
}

/* Retina-ready images */
@mixin retina-image($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}

/* Position */
@mixin position($top: auto, $right: auto, $bottom: auto, $left: auto, $position: absolute) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

/* Fixed aspect ratio */
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .inner-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* Box */
@mixin box($width: 0, $height: $width) {
  height: $height;
  width: $width;
}

/* Opacity */
// Usage: @include opacity(.4);
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); //IE8
}

/* Center block */
@mixin center-block($display: block) {
  display: $display;
  margin-left: auto;
  margin-right: auto;
}

/* Gradients */
// Params: $start-color, $end-color, $orientation - vertical/horizontal/radial
// Usage: @include background-gradient(red, black, 'vertical');
@mixin background-gradient($start-color, $end-color, $orientation) { 
  background: $start-color;

  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

/* Text shorten */
@mixin text-shorten {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Media screen - custom dimensions */
@mixin media-screen($type: max, $pixels: 0) {
  @media screen and ($type + -width: $pixels + px) {
    @content;
  }
}

/* Transformation */
@mixin set-transform($args...) {
  transform: $args;
}

/* background image */
@mixin bg-img {
  position: absolute;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

/* fill container */
@mixin fill-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/* Encode symbols */
@function url-encode($string) {
  $map: (
    '%': '%25',
    '<': '%3C',
    '>': '%3E',
    ' ': '%20',
    '!': '%21',
    '*': '%2A',
    "'": '%27',
    '"': '%22',
    '(': '%28',
    ')': '%29',
    ';': '%3B',
    ':': '%3A',
    '@': '%40',
    '&': '%26',
    '=': '%3D',
    '+': '%2B',
    '$': '%24',
    ',': '%2C',
    '/': '%2F',
    '?': '%3F',
    '#': '%23',
    '[': '%5B',
    ']': '%5D'
  );

  $new: $string;

  @each $search, $replace in $map {
    $new: str-replace($new, $search, $replace);
  }

  @return $new;
}

/* Format the SVG as a URL */
@function inline-svg($string) {
  @return url('data:image/svg+xml,#{url-encode($string)}');
}

@function inline-svg-clean($string) {
  @return url('data:image/svg+xml,#{$string}');
}