/* Extends */
.breadcrumb-style {
  font-size: var(--text-xs);
  color: var(--color-body);
}

.tagline-style {
  font-size: var(--text-xs);
  font-weight: bold;
  letter-spacing: em-to-px(0.1);
  text-transform: uppercase;
}

.cta-style {
  font-size: var(--text-md);
  font-weight: 900;
  text-transform: uppercase;
}

.input-field-style {
  font-size: var(--text-xxs);
  font-weight: bold;
  text-transform: uppercase;
}