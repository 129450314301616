.wkb-cards {
  padding-left: 100px;
  padding-right: 100px;

  @include media-breakpoint-down(lg) {
    padding-left: 0px;
    padding-right: 0px;
  }

  >.container {
    padding-left: 0px;
    padding-right: 0px;

    @include media-breakpoint-down(lg) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 32px;

    &-one-row {
      grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
    }

    &-fixed {
      display: flex;
      justify-content: center;
    }

    &-grid-2 {
      grid-template-columns: 1fr 1fr;
      max-width: 1036px;
      margin: 0 auto;
    }

    &-grid-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &-grid-4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &-grid-6 {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    @include media-breakpoint-down(xl) {
      &-grid-4,
      &-grid-6 {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    @include media-breakpoint-down(lg) {
      &-grid-3,
      &-grid-4,
      &-grid-6 {
        grid-template-columns: 1fr 1fr;
      }
    }

    @include media-breakpoint-down(md) {
      &-grid-3,
      &-grid-4,
      &-grid-6 {
        grid-template-columns: 1fr;
      }
    }

    @include media-breakpoint-down(sm) {
      &-grid-2,
      &-grid-3,
      &-grid-4,
      &-grid-6 {
        grid-template-columns: 1fr;
      }
    }

    .card-link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    img {
      width: 100%;
    }

    .card { 
      position: relative;

      a {
        color: $f_text-color;
      }

      &.card-bg {
        background-color: $f_card-bg;
      }

      &.elevation {
        transition: .3s;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);

        // &:hover {
        //   box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);
        // }
      }

      &-body {
        padding: $f_spacer-7 $f_spacer-7 $f_spacer-8 $f_spacer-7;
        // min-height: 300px;
      }

      &-small {
        max-width: 290px;
      }

      &-wide {
        max-width: 502px;
      }

      .btn {
        &-full {
          width: calc(100% - ($f_spacer-7 * 2));
        }
      }
    }
  }

  .owl-stage {
    padding-bottom: 20px;
  }
}