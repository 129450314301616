@import "common/icons_variables";
@import "common/variables";
@import "common/variables-bs";

/** Import everything from autoload */
@import "autoload/_bootstrap.scss";

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";
@import '~owl.carousel/dist/assets/owl.carousel.css';
@import "~lightbox2/dist/css/lightbox";
@import "~animate.css";
@import "~select2/dist/css/select2.min.css";

/** Import theme styles */
@import "common/functions";
@import "common/extends";
@import "common/mixins";
@import "common/typography";
@import "common/global";
@import "common/icons";

@import "components/buttons";
//@import "components/comments";
@import "components/forms";
@import "components/wp-classes";
@import "components/post-card";
@import "components/search-modal";
@import "components/cards";
@import "components/select";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/woocommerce";
@import "layouts/archive";

/** Import modules */
@import "modules/heading";
@import "modules/banner";
@import "modules/spacer";
@import "modules/image";
@import "modules/accordion";
@import "modules/gallery";
@import "modules/text";
@import "modules/video";
@import "modules/section";
@import "modules/google-maps";
@import "modules/posts";
@import "modules/testimonials";
@import "modules/numbers";
@import "modules/tabs";
@import "modules/file-download";
@import "modules/timeline";
@import "modules/cards";
@import "modules/text_with_image";
@import "modules/map";
@import "modules/content-block";