.wkb-gallery {
  overflow: hidden;

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .image {
    @include fill-container;
    background-position: center;
    background-size: cover;
  }
  
  &--slider {
    padding-left: 60px;
    padding-right: 60px;
  }

  .owl-carousel .owl-nav {
    .owl-prev,
    .owl-next {
      position: absolute;
      font-size: 100px;
      color: $primary;
      top: 50%;
      transform: translateY(-50%);
      outline: none;
    }

    .owl-next { 
      right: -50px;
    }

    .owl-prev {
      left: -50px;
    }
  }

  @include media-breakpoint-down(sm) {
    .owl-carousel .owl-nav {
      .owl-prev,
      .owl-next {
        font-size: 70px;
      }

      .owl-next { 
        right: -20px;
      }
  
      .owl-prev {
        left: -20px;
      }
    }
  }
}