.select2-dropdown {
  top: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
  border: none;

  &::before {
    width: 15px;
    height: 15px;
    position: absolute;
    content: '';
    background-color: $white;
    top: -2px;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    z-index: -1;
  }
}

.select2-container--default .select2-results>.select2-results__options {
  border-radius: 4px;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: $primary;
  color: $white;
}

.select2-container--default .select2-results__option--selected {
  background-color: $white;
}

.select2-results {
  font-size: 20px;
}

.select2-container--open .select2-dropdown--below {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}