.container-small {
  @include make-container();
  max-width: $container-small;
}

.container-medium {
  @include make-container();
  max-width: $f_container-lg;
}

.none > .row {
  @extend .g-0;
}

.bg-dark {
  color: $white;
}

img {
  max-width: 100%;
}

.img-overlay {
  background-color: $dark;
  @include fill-container;
  z-index: 2;

  &.light {
    background-color:$light;
  }
}

// Back to top button
#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  z-index: 99;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 4px;

  .icon-chevron-up {
    &::before {
      background-color: $white;
    }

    &:hover::before {
      background-color: $white;
    }
  }
}

// animation
.animate__animated {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.animate_start {
    opacity: 1;
  }
}

// widgets
.widget_wk_template_widget {
  clear: both;
  
  > .wkb-template {
    padding: 0 !important;
  }
}

// body:not(.home) div.wrap {
//   margin-top: 132px;

//   @include media-breakpoint-down(sm) {
//     margin-top: 65px;
//   }
// }

body {
  color: $f_secondary;
}

body.home .wkb-text-with-image  {
  .row {
    align-items: center;
  }
  .image img {
    height: 400px;
  }
}

body:not(.home) {
  .wkb-banner {
    .content .content-inner {
      background-color: $f_primary-light !important;
      color: $f_gray-950 !important;
      padding: 45px 80px 45px 20px !important;
  
      &::before {
        background-color: $f_primary-light !important;
      }
  
      .text {
        margin-bottom: 0px !important;

        p {
          margin-bottom: 0px !important;
        }
      }
    }
  }

  .breadcrumb {
    margin-top: 32px!important;
  }
}

body.content-down div.wrap {
  margin-top: 132px;

  @include media-breakpoint-down(sm) {
    margin-top: 65px;
  }
}

@media (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
      display: block;
  }
}