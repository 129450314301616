.wkb-testimonials {
  .icon {
    position: absolute;
    width: 70px;
    height: 46px;

    &::before {
      background-color: $primary;
    }
  }
}

.wkb-testimonials.layout-card {
  .controles-container {
    display: none;
  }

  .wkb-testimonials__inner {
    &:not(.owl-carousel) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      // grid-template-columns: repeat(auto-fit, 350px);
      // gap: 72px;
      gap: 20px;
      justify-content: space-between;
    }
    
    // @include media-breakpoint-down(lg) {
    //   grid-template-columns: repeat(1, 1fr);
    // }

    &-card {
      padding: map-get($spacers, 9);
      background-color: $bg-primary;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      text-align: center;
      max-width: 350px;

      img {
        width: initial;
      }

      // &:last-child {
      //   margin-bottom: 0;
      // }

      .icon-quote {
        &-default {
          top: -22px;
          right: -14px;
          width: 80px;
          height: 51px;
        }
        &-special {
          top: -35px;
          left: 50%;
          transform: translateX(-50%);
          width: 77px;
          height: 51px;
        }

        // @if $f_quote_name == 'default' {
        //   top: -12px;
        //   right: -14px;
        // } @else {
        //   top: -33px;
        //   left: 50%;
        //   transform: translateX(-50%);
        // }
      }

      // img {
      //   border-radius: 50%;
      // }

      &.no-quotes {
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &:not(.owl-carousel) .wkb-testimonials__inner-card {
      margin: 0 auto $f_spacer-9 auto;
    }


    .owl-stage {
      padding-top: 25px;
      padding-bottom: 20px;
    }

    
    &.owl-carousel + .controles-container {
      display: flex;
    }
  }
}

.wkb-testimonials.layout-block {
  .wkb-testimonials__inner {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 72px;

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(1, 1fr);
    }

    &-card {
      background-color: $bg-primary;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      .info {
        padding: 78px 160px 78px 64px;
        position: relative;

        @include media-breakpoint-down(lg) {
          padding: 56px 64px 72px 64px;
        }

        @include media-breakpoint-down(md) {
          padding: 32px;
        }

        &-name {
          background-color: $f_accent;
          padding-left: 165px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;

          @include media-breakpoint-down(lg) {
            padding-left: 0;
            height: 226px;
            width: 100%;
            padding-bottom: 40px;
            justify-content: flex-end;
          }
        }
      }

      .icon {
        opacity: .5;

        &-quote-default,
        &-quote-special {
          position: relative;
        }
      }

      img {
        width: 220px;
        height: 220px;
        margin-left: -110px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        right: -110px;

        @include media-breakpoint-down(lg) {
          width: 186px;
          height: 186px;
          margin-left: 0;
          top: auto;
          left: 50%;
          transform: translateX(-50%);
          right: auto;
          bottom: -93px;
        }

        @include media-breakpoint-down(md) {
          width: 164px;
          height: 164px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      img {
        border-radius: 50%;
      }
    }
  }
}