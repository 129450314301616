.wkb-banner {
  position: relative;
  width: 100%;
  // height: 60vh;
  overflow: hidden;

  &.wkb-banner--full {
    height: 100vh;
  }

  > .container-fluid {
    padding: 0;
  }

  &--hero {
    height: 100vh;

    @include media-breakpoint-down(sm) {
      height: 570px;
    }

    .container {
      // max-width: $f_container-xl !important;
    }
    .item {
      overflow: hidden;

      .icon.icon-chevron-down {
        position: absolute;
        bottom: 35px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;

        &::before {
          background-color: $white !important;
        }
      }
    }

    .content {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: flex-end !important;

      // &:before {
      //   content: '';
      //   display: block;
      //   width: 3000px;
      //   height: 100%;
      //   position: absolute;
      //   right: 50%;
      //   top: 0;
      //   background-color: $f_image-overlay;
        
      //   @include media-breakpoint-down(md) {
      //     display: none;
      //   }
      // }

      .content-inner {
        padding: $f_spacer-10 $f_spacer-14 ($f_spacer-10 + 50) 20px;
        box-sizing: border-box;
        max-width: 665px;
        position: relative;
        z-index: 2;
        background-color: $f_image-overlay;

        &:before {
          content: '';
          display: block;
          width: 3000px;
          height: 100%;
          position: absolute;
          right: 100%;
          top: 0;
          background-color: $f_image-overlay;
        }

        @include media-breakpoint-down(md) {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          // height: 100%;
          height: fit-content;
          align-items: flex-start;
          padding: ($f_spacer-9 + 100) $f_spacer-11 $f_spacer-16 20px;
        }

        @include media-breakpoint-down(sm) {
          padding: $f_spacer-9 $f_spacer-11 $f_spacer-16 20px;
        }
      }
    }
  }

  > .container-fluid,
  > .owl-carousel,
  .owl-stage-outer, 
  .owl-stage,
  .owl-item,
  .item .container {
    height: 100%;
  }

  .item {
    height: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
  }

  &.parallax {
    .owl-carousel:not(.owl-loaded) {
      .image {
        background-attachment: fixed;
      }
    }
  }

  .owl-carousel {
    display: block;
  }

  .image {
    @include fill-container();
    background-size: cover;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center;
  }

  .play-btn {
    @include centerer(both);
    z-index: 4;
    display: flex;
    align-items: center;
    cursor: pointer;
    left: calc(50% + 100px);

    .icon {
      background-image: url('../images/play-video.svg');
      background-position: center;
      background-size: cover;
      width: 100px;
      height: 100px;
      display: block;
      margin-right: 20px;
    }
  }

  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    display: none;
    z-index: 3;
  }

  .yt-video {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: none;
  }

  &.playing {

    .play-btn {
      display: none;
    }

    .video,
    .yt-video {
      display: block;
    }
  }
  .content {
    position: relative;
    z-index: 3;
    color: $white;
    height: auto;

    > .row {
      height: 100%;
      align-items: center;
    }
  }

  .owl-carousel .owl-nav {
    .owl-prev,
    .owl-next {
      position: absolute;
      font-size: 100px;
      color: $primary;
      top: calc(50% - 15px);
      outline: none;
    }

    .owl-next { 
      right: 20px;
    }

    .owl-prev {
      left: 20px;
    }
  }
}

// -----> Steps
.wkb-banner {
  &--hero {
    .wkb-banner-nav-dots {
      left: 20px;
      // @include media-breakpoint-down(lg) {
      //   left: $f_spacer-14;
      // }
      

      @include media-breakpoint-down(md) {
        // left: $f_spacer-9;
        bottom: $f_spacer-9 !important;
      }
    }
  }

  .wkb-banner-nav-dots {
    position: absolute;
    text-align: left;
    z-index: 1;
    bottom: 50px;
  
    .owl-dot {
      width: 32px;
      height: 8px;
      background: $primary;
      margin: 0 2px;
      border-radius: 4px;
      border: none;
      transition: .3s;
  
      &.active {
        width: 96px;
      }
      
      &:not(.active) {
        background: $gray-500;
      }
    }
  }
}

// -----> Dots
// .wkb-banner {
//   .wkb-banner-nav-dots {
//     position: absolute;
//     text-align: left;
//     z-index: 1;
//     bottom: 50px;
  
//     .owl-dot {
//       width: 8px;
//       height: 12px;
//       background: $primary;
//       margin: 0 6px;
//       border-radius: 16px;
//       border: none;
//       transition: .3s;
  
//       &:not(.active) {
//         background: $gray-500;
//       }
//     }
//   }
// }

// -----> Progress
// .wkb-banner {
//   .wkb-banner-nav-dots {
//     position: absolute;
//     text-align: left;
//     z-index: 1;
//     bottom: 50px;

//     button {
//       &:first-child {
//         border-radius: 4px 0 0 4px;
//       }
  
//       &:last-child {
//         border-radius: 0 4px 4px 0;
//       }
//     }
  
//     .owl-dot {
//       width: 32px;
//       height: 8px;
//       background: $primary;
//       margin: 0;
//       border: none;
//       transition: .3s;
  
//       &.active {
//         width: 32px;
//       }
      
//       &:not(.active) {
//         background: $gray-500;
//       }
//     }
//   }
// }