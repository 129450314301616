footer.content-info {
  position: relative;
  padding-top: map-get($spacers, 9);
  padding-bottom: map-get($spacers, 9);
  padding-left: 100px;
  padding-right: 100px;

  @include media-breakpoint-down(lg) {
    padding-left: 0px;
    padding-right: 0px;
  }

  a {
    color: $white;
    text-decoration: none;
  }

  .widget_nav_menu {
    ul {
      list-style: none;
      padding-left: 10px;
    }

    > ul {
      padding-left: 0;
    }
  }

  .social-title {
    margin-bottom: 10px;
    line-height: 20px;
  }

  .social-link {
    color: $white;
    // margin-right: 10px;
    font-size: 32px;
    display: flex;
  }
  
  .navbar-brand {
    width: 90px;
  }

  hr {
    border-top: 1px solid $white;
    opacity: 1;
    margin: 0;
  }

  .footer-logo img {
    height: 48px;
    width: auto;
  }

  .footer-top {
    a {
      line-height: 20px;
    }

    .menu {
      margin-bottom: 0;
    }

    section.widget_nav_menu li {
      margin-bottom: map-get($spacers, 7);
    }

    ul.sub-menu {
      display: none;
    }
  }

  .footer-bottom {
    align-items: flex-end;

    .icon {
      &::before {
        background-color: $white !important;
      }

      &:hover::before {
        background-color: $white !important;
      }
    }

    p {
      margin-bottom: 0;
    }

    .widget_wk_social_widget {
      display: flex;
      justify-content: center;
      gap: map-get($spacers, 7);
    }

    &-copyright {
      text-align: center;
    }
  }

  .widget_media_gallery .gallery {
    display: flex;
    gap: 18px;
    flex-direction: row;
    flex-wrap: wrap;
  }
}