/* Icons */

.icon {
	display: block;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    transition: background-color 0.15s ease;
    background-color: $gray-700;
	}

  &:hover::before {
    background-color: $gray-900 !important;
  }

  &-chevron-down {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($chevron-down);
      mask-image: inline-svg($chevron-down);
    }
  }

  &-chevron-left {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($chevron-left);
      mask-image: inline-svg($chevron-left);
    }
  }

  &-chevron-right {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($chevron-right);
      mask-image: inline-svg($chevron-right);
    }
  }

  &-chevron-up {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($chevron-up);
      mask-image: inline-svg($chevron-up);
    }
  }

  &-chevron-double-down {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($chevron-double-down);
      mask-image: inline-svg($chevron-double-down);
    }
  }

  &-chevron-double-left {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($chevron-double-left);
      mask-image: inline-svg($chevron-double-left);
    }
  }

  &-chevron-double-right {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($chevron-double-right);
      mask-image: inline-svg($chevron-double-right);
    }
  }

  &-chevron-double-up {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($chevron-double-up);
      mask-image: inline-svg($chevron-double-up);
    }
  }

  &-close {
    width: 32px;
    height: 32px;

    &::before {
      -webkit-mask-image: inline-svg($close);
      mask-image: inline-svg($close);
    }
  }

  &-edit {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($edit);
      mask-image: inline-svg($edit);
    }
  }

  &-hamburger {
    width: 32px;
    height: 32px;

    &::before {
      -webkit-mask-image: inline-svg($hamburger);
      mask-image: inline-svg($hamburger);
    }
  }

  &-password-hidden {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($password-hidden);
      mask-image: inline-svg($password-hidden);
    }
  }

  &-password-shown {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($password-shown);
      mask-image: inline-svg($password-shown);
    }
  }

  // &-pin {
  //   width: 56px;
  //   height: 56px;

  //   &::before {
  //     -webkit-mask-image: inline-svg($pin);
  //     mask-image: inline-svg($pin);
  //   }
  // }

  &-play {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($play);
      mask-image: inline-svg($play);
    }
  }

  &-yes {
    width: 56px;
    height: 56px;

    &::before {
      -webkit-mask-image: inline-svg($yes);
      mask-image: inline-svg($yes);
    }
  }

  &-mail {
    width: 24px;
    height: 24px;

    &::before {
      -webkit-mask-image: inline-svg($mail);
      mask-image: inline-svg($mail);
    }
  }

  &-phone {
    width: 24px;
    height: 24px;

    &::before {
      -webkit-mask-image: inline-svg-clean($phone);
      mask-image: inline-svg-clean($phone);
    }
  }

  &-download {
    width: 20px;
    height: 20px;

    &::before {
      -webkit-mask-image: inline-svg($download);
      mask-image: inline-svg($download);
      background-color: $white;
    }

    &:hover::before {
      background-color: $white;
    }
  }

  &-search {
    width: 32px;
    height: 32px;

    &::before {
      -webkit-mask-image: inline-svg($search);
      mask-image: inline-svg($search);
    }
  }

  &-quote {
    width: 32px;
    height: 32px;

    &-default::before {
      -webkit-mask-image: inline-svg($quote-default);
      mask-image: inline-svg($quote-default);
    }

    &-special::before {
      -webkit-mask-image: inline-svg($quote-special);
      mask-image: inline-svg($quote-special);
    }
  }

  &-facebook {
    width: 32px;
    height: 32px;

    &::before {
      -webkit-mask-image: inline-svg($facebook);
      mask-image: inline-svg($facebook);
      border-radius: $f_border-radius-circle;
    }
  }

  // &-instagram {
  //   width: 32px;
  //   height: 32px;

  //   &::before {
  //     -webkit-mask-image: inline-svg($instagram);
  //     mask-image: inline-svg($instagram);
  //     border-radius: $f_border-radius-circle;
  //   }
  // }

  &-linkedin {
    width: 32px;
    height: 32px;

    &::before {
      -webkit-mask-image: inline-svg($linkedin);
      mask-image: inline-svg($linkedin);
      border-radius: $f_border-radius-circle;
    }
  }

  &-x {
    width: 32px;
    height: 32px;

    &::before {
      -webkit-mask-image: inline-svg($x);
      mask-image: inline-svg($x);
      border-radius: $f_border-radius-circle;
    }
  }

  &-youtube {
    width: 32px;
    height: 32px;

    &::before {
      -webkit-mask-image: inline-svg($youtube);
      mask-image: inline-svg($youtube);
      border-radius: $f_border-radius-circle;
    }
  }
}