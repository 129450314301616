.wkb-google-maps {
  position: relative;
  overflow: hidden;

  .content-info a {
    color: $f_gray-700;
  }

  .map-container {
    position: relative;

    &__image {
      width: 100%;
      height: 780px;
      background-position: calc(50% + 400px);
      background-size: cover;

      @include media-breakpoint-down(lg) {
        background-position: 50%;
      }

      @include media-breakpoint-down(md) {
        height: 416px;
      }
    }
  }

  &.no-image {
    background: $primary;

    .content {
      position: relative;
    }

    .map-container__image {
      height: auto;
    }
  }

  .content-container {
    position: absolute;
    bottom: 0;
    width: 100%;

    @include media-breakpoint-down(md) {
      position: relative;
    }
  }

  .content {
    padding: map-get($spacers, 18) map-get($spacers, 9) 0 0;

    &:before {
      content: "";
      display: block;
      width: 3000px;
      height: 100%;
      position: absolute;
      right: 100%;
      top: 0;
      background-color: white;
    }

    @include media-breakpoint-down(md) {
      padding: map-get($spacers, 10) map-get($spacers, 9) 0 0 !important;
    }
  }

  .social-icons {
    position: absolute;
    right: 48px;
    top: 88px;
    font-size: 32px;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: map-get($spacers, 7);

    @include media-breakpoint-down(lg) {
      position: relative;
      right: auto;
      top: auto;
      flex-direction: row;
    }

    a {
      color: $f_base-color;
      text-decoration: none;
    }
  }

  .contact-icons {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    gap: map-get($spacers, 8);
    top: 0;
    right: -76px;
    padding: map-get($spacers, 8);
    background: $body-bg;

    @include media-breakpoint-down(lg) {
      position: relative;
      flex-direction: row;
      top: auto;
      right: auto;
      padding: 0;
      padding-bottom: 40px;
    }

    .icon-phone, .icon-mail {
      color: $f_base-color;
      background: $body-bg;
      font-size: 24px;
    }
  }
}

// Google maps Alt
// .wkb-google-maps {
//   position: relative;
//   overflow: hidden;

//   .map-container {
//     position: relative;

//     &__image {
//       width: 100%;
//       height: 100%;
//       background-size: cover;
//       min-height: 573px;
//       background-position: 50%;

//       @include media-breakpoint-down(lg) {
//         height: 573px;
//         min-height: auto;
//       }

//       @include media-breakpoint-down(md) {
//         height: 416px;
//       }
//     }
//   }

//   &.no-image {
//     background: $primary;

//     .content {
//       position: relative;
//     }

//     .map-container__image {
//       height: auto;
//     }
//   }

//   .content {
//     // position: absolute;
//     // bottom: 0;
//     // max-width: 690px;
//     // width: 100%;

//     @include media-breakpoint-down(lg) {
//       max-width: 100%;
//       position: relative;
//       padding: 40px 32px ;
//     }
//   }

//   .social-icons {
//     font-size: 32px;
//     display: flex;
//     flex-direction: row;
//     gap: map-get($spacers, 7);

//     @include media-breakpoint-down(lg) {
//       position: relative;
//       right: auto;
//       top: auto;
//       flex-direction: row;
//     }

//     a {
//       color: $f_base-color;
//       text-decoration: none;
//     }
//   }

//   .contact-icons {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     gap: map-get($spacers, 8);

//     @include media-breakpoint-down(lg) {
//       position: relative;
//       flex-direction: row;
//       top: auto;
//       right: auto;
//       padding: 0;
//       margin-bottom: 40px;
//     }

//     .icon-phone, .icon-mail {
//       color: $f_base-color;
//       background: $body-bg;
//       font-size: 24px;
//     }
//   }
// }