.wkb-file-download {
  &__inner {
    display: grid;
    grid-template-columns: minmax(0, max-content);
    row-gap: 16px;

    &.file-download-grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      @include media-breakpoint-down(md) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &-item {
      background-color: $f_bg-primary;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 14px;
      align-items: center;
    }

    .button {
      padding: 8px;
      background-color: $primary;
      border-radius: 50%;
      display: flex;
    }

    .icon-download {
      &::before {
        background-color: $white;
      }

      &:hover::before {
        background-color: $white !important;
      }
    }
  }
}