.wkb-timeline {
  position: relative;
  z-index: 0;
  font-size: 15px;
  margin-bottom: 40px;

  &-line {
    &:before {
      content: "";
      position: absolute;
      top: 10px;
      left: 50%;
      width: 1px;
      height: calc(100% - 10px);
      background-color: $f_base-color;
    }
  }

  .year {
    font-size: 25px;
    line-height: 30px;
    font-weight: 900;
    margin-bottom: 20px;
    position: relative;
  }

  &-bullet {
    margin: -60px 0 60px 50%;
    padding: 0 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;

    &:first-child {
      margin-top: 0;
    }

    &:nth-child(2n) {
      margin: -40px 50% 80px 0;

      &:before {
        left: auto;
        right: 0;
        transform: translateX(50%);
      }
    }

    &:before {
      content: "";
      width: 18px;
      height: 18px;
      position: absolute;
      left: 0;
      top: 5px;
      background-color: $primary;
      border-radius: 50%;
      transform: translateX(-50%);
    }
  }
}

// Move this code
@media (max-width: 991px) {
  .wkb-timeline .wkb-timeline-line:before {
    left: auto;
    margin-left: 8px;
  }
  .wkb-timeline .wkb-timeline-bullet,
  .wkb-timeline .wkb-timeline-bullet:nth-child(2n) {
    margin: 0 0 15px 0;
    text-align: left;
    padding: 0 0 0 30px;
  }
  .wkb-timeline .wkb-timeline-bullet:before,
  .wkb-timeline .wkb-timeline-bullet:nth-child(2n):before {
    left: 0;
    right: auto;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
  }
}