.wkb-numbers {
  &__inner {
    display: grid;
    // grid-template-columns: repeat(4, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(1, 1fr);
      max-width: 320px;
      margin: auto;
    }

    &-card {
      padding: map-get($spacers, 9);
      background-color: $body-bg;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .numbers {
        display: flex;
        align-items: center;
        color: $primary;
        gap: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.with-divider {
      .wkb-numbers__inner-card {
        &::after {
          width: 1px;
          background-color: black;
          position: absolute;
          content: '';
          height: 168px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
  
          @include media-breakpoint-down(md) {
            height: 1px;
            width: 168px;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            top: auto;
          }
        }
  
        @include media-breakpoint-down(lg) {
          &:nth-child(even)::after {
            display: none;
          }
        }
  
        @include media-breakpoint-down(md) {
          &:nth-child(even)::after {
            display: block;
          }
        }
  
        &:last-child::after {
          display: none;
        }
      }
    }
  }
}