.wkb-text-with-image {
  .content {
    h5 {
      margin-bottom: map-get($spacers, 4);
    }

    h3,p {
      margin-bottom: map-get($spacers, 8);
    }
  }

  .image {
    position: relative;
    display: inline-block;
    width: 100%;
    
    img {
      position: relative;
      z-index: 2;
      width: 100%;
    }
  } 

  .square-secondary-light,
  .square-primary-light {
    position: relative;
    width: 72%;
    padding-bottom: 72%;
    // padding-bottom: 56%;
    position: absolute;
    z-index: 1;
    left: -15px;
    bottom: -30px;
  }

  .square-secondary-light {
    background-color: $f_secondary-light;
  }

  .square-primary-light {
    background-color: $f_primary-light;
  }

  .order-1 {
    .square-secondary-light,
    .square-primary-light {
      right: -15px;
      top: -30px;
      left: initial;
      bottom: initial;
    }
  }
}