/* Search modal */

body.modal-open {
  .icon-close {
    color: $white;
    position: absolute;
    right: 28px;
    top: 28px;
    
    &::before {
      background-color: $white;
    }

    &:hover::before {
      background-color: $white !important;
    }
  }
  
  .search-input {
    display: flex;
    align-items: center;
    gap: 24px;

    .icon-search {
      display: inline;
      
      &:hover::before {
        background-color: $white !important;
      }
    }
  }

  #search {
    &:focus {
      box-shadow: none;
      border-color: $primary;
    }
  }

  .modal-search .search-form {
    width: 100%;
    justify-content: space-between;

    .btn-icon {
      display: none;
    }

    .btn {
      display: inline-block;
    }

    .form-inline {
      display: flex;
      gap: 40px;
      justify-content: center;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
      }
    }

    .form-control {
      max-width: 356px;
      width: 100%;
      border-width: 0 0 1px 0;
      border-radius: 0;
      border-color: $white;
      background: transparent;
      padding: 0;
      font-size: 24px;
      color: $white;

      &::placeholder {
        color: $white;
        opacity: 1;
      }
      
      &::-ms-input-placeholder {
        color: $white;
      }
      
      &::-ms-input-placeholder {
        color: $white;
      }
    }
  }
}

/* Bootstrap modal */

body.modal-open {
  .modal-lg {
    max-width: 722px;
  }

  header {
    width: calc(100% - 17px) !important;
  }

  .sticky-nav {
    right: calc(0px + 17px) !important;
  }

  .modal__inner {
    pointer-events: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 100px 0;
  }

  .modal-backdrop {
    background-color: $gray-900;
  
    &.show {
      opacity: 0.95;
    }
  }
}