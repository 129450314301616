.wkb-text {
  //fix for overflowng margin
  padding-bottom: 1px;

  ::-webkit-scrollbar{
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
      background: $gray-500;
      border-radius: $border-radius-circle;
  }

  .table-wrap {
    overflow-x: auto;
    overflow-y: hidden;
  }

  table {
    min-width: 500px;
  }

  th {
    background-color: $f_table-header-color;
    padding: $f_spacer-7;
  }

  td {
    padding: $f_spacer-7;
  }

  tr {
    border-bottom: 1px solid $f_bg-primary;
  }

  tr:nth-child(even) {
    background-color: $f_tabel-row-even-color;
  }

  tr:nth-child(odd) {
    background-color: $f_table-row-odd-color;
  }

  > div {
    max-width: 720px;
  }
}
