body.single {
  article {
    max-width: 100%;
    .container-fluid,
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .updated {
    margin-bottom: 10px;
    margin-left: 0;
  }

  @media (max-width: 576px) {
    .updated {
      flex-wrap: wrap;
    }
  }
}

.novice .meta-data {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}