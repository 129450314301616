/**
 * WordPress Generated Classes
 * @see http://codex.wordpress.org/CSS#WordPress_Generated_Classes
 */
@use "sass:math";

/** Media alignment */
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}

.aligncenter {
  display: block;
  margin: math.div($spacer, 2) auto;
  height: auto;
}

.alignleft,
.alignright {
  margin-bottom: math.div($spacer, 2);
  height: auto;
}

@include media-breakpoint-up(sm) {
  .alignleft {
    float: left;
    margin-right: math.div($spacer, 2);
  }

  .alignright {
    float: right;
    margin-left: math.div($spacer, 2);
  }
}

/** Text meant only for screen readers */
.screen-reader-text {
  @extend .visually-hidden;
  @extend .visually-hidden-focusable;
}
