%heading {
  margin-top: 0; // 1
  margin-bottom: 20px; // TODO
}

h1, .h1 {
  @extend %heading;
  font-size: $f_h1-main-d-size;
  font-weight: $f_h1-main-d-weight;
  line-height: $f_h1-main-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_h1-main-t-size;
    font-weight: $f_h1-main-t-weight;
    line-height: $f_h1-main-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_h1-main-m-size;
    font-weight: $f_h1-main-m-weight;
    line-height: $f_h1-main-m-line-height;
  }
}

.h1--product {
  @extend %heading;
  font-size: $f_h1-product-d-size;
  font-weight: $f_h1-product-d-weight;
  line-height: $f_h1-product-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_h1-product-t-size;
    font-weight: $f_h1-product-t-weight;
    line-height: $f_h1-product-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_h1-product-m-size;
    font-weight: $f_h1-product-m-weight;
    line-height: $f_h1-product-m-line-height;
  }
}

.h1--other {
  @extend %heading;
  font-size: $f_h1-other-d-size;
  font-weight: $f_h1-other-d-weight;
  line-height: $f_h1-other-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_h1-other-t-size;
    font-weight: $f_h1-other-t-weight;
    line-height: $f_h1-other-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_h1-other-m-size;
    font-weight: $f_h1-other-m-weight;
    line-height: $f_h1-other-m-line-height;
  }
}

h2, .h2 {
  @extend %heading;
  font-size: $f_h2-d-size;
  font-weight: $f_h2-d-weight;
  line-height: $f_h2-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_h2-t-size;
    font-weight: $f_h2-t-weight;
    line-height: $f_h2-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_h2-m-size;
    font-weight: $f_h2-m-weight;
    line-height: $f_h2-m-line-height;
  }
}

h3, .h3 {
  @extend %heading;
  font-size: $f_h3-d-size;
  font-weight: $f_h3-d-weight;
  line-height: $f_h3-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_h3-t-size;
    font-weight: $f_h3-t-weight;
    line-height: $f_h3-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_h3-m-size;
    font-weight: $f_h3-m-weight;
    line-height: $f_h3-m-line-height;
  }
}

h4, .h4 {
  @extend %heading;
  font-size: $f_h4-d-size;
  font-weight: $f_h4-d-weight;
  line-height: $f_h4-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_h4-t-size;
    font-weight: $f_h4-t-weight;
    line-height: $f_h4-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_h4-m-size;
    font-weight: $f_h4-m-weight;
    line-height: $f_h4-m-line-height;
  }
}

h5, .h5 {
  @extend %heading;
  font-size: $f_h5-d-size;
  font-weight: $f_h5-d-weight;
  line-height: $f_h5-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_h5-t-size;
    font-weight: $f_h5-t-weight;
    line-height: $f_h5-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_h5-m-size;
    font-weight: $f_h5-m-weight;
    line-height: $f_h5-m-line-height;
  }
}

h6, .h6 {
  @extend %heading;
  font-size: $f_h6-d-size;
  font-weight: $f_h6-d-weight;
  line-height: $f_h6-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_h6-t-size;
    font-weight: $f_h6-t-weight;
    line-height: $f_h6-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_h6-m-size;
    font-weight: $f_h6-m-weight;
    line-height: $f_h6-m-line-height;
  }
}

p, .p {
  @extend %heading;
  font-size: $f_body-d-size;
  font-weight: $f_body-d-weight;
  line-height: $f_body-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_body-t-size;
    font-weight: $f_body-t-weight;
    line-height: $f_body-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_body-m-size;
    font-weight: $f_body-m-weight;
    line-height: $f_body-m-line-height;
  }
}

.p--large {
  @extend %heading;
  font-size: $f_body-large-d-size;
  font-weight: $f_body-large-d-weight;
  line-height: $f_body-large-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_body-large-t-size;
    font-weight: $f_body-large-t-weight;
    line-height: $f_body-large-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_body-large-m-size;
    font-weight: $f_body-large-m-weight;
    line-height: $f_body-large-m-line-height;
  }
}

.p--small {
  @extend %heading;
  font-size: $f_body-small-d-size;
  font-weight: $f_body-small-d-weight;
  line-height: $f_body-small-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_body-small-t-size;
    font-weight: $f_body-small-t-weight;
    line-height: $f_body-small-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_body-small-m-size;
    font-weight: $f_body-small-m-weight;
    line-height: $f_body-small-m-line-height;
  }
}

.display-1 {
  font-size: $f_display1-d-size;
  font-weight: $f_display1-d-weight;
  line-height: $f_display1-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_display1-t-size;
    font-weight: $f_display1-t-weight;
    line-height: $f_display1-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_display1-m-size;
    font-weight: $f_display1-m-weight;
    line-height: $f_display1-m-line-height;
  }
}

.display-2 {
  font-size: $f_display2-d-size;
  font-weight: $f_display2-d-weight;
  line-height: $f_display2-d-line-height;

  @include media-breakpoint-down(lg) {
    font-size: $f_display2-t-size;
    font-weight: $f_display2-t-weight;
    line-height: $f_display2-t-line-height;
  }

  @include media-breakpoint-down(sm) {
    font-size: $f_display2-m-size;
    font-weight: $f_display2-m-weight;
    line-height: $f_display2-m-line-height;
  }
}
