.wkb-accordion {

  .item-header {
    cursor: pointer;

    .icon {
      transition: all 0.2s ease-in-out;
      display: inline-block;
      margin-right: 10px;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 17px 16px;

      &:not(.collapsed) {
        background-color: $f_base-color-inverted;
      }
    }
  }

  .item {
    border-bottom: 1px solid $gray-500;
  }

  .collapsing, .collapse.show {
    background-color: $f_base-color-inverted;
  }

  [aria-expanded=true] {
    border-bottom: none;

    .icon {
      transform: rotate(180deg);
    }
  }
}