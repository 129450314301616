/* Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

/* Broder radius */
$border-radius: 5px;

/* Typography */
$font-family-base: $f_font-family;
$font-size-base: $f_font-size;
$font-weight-base: $f_font-weight;
$line-height-base: $f_line-height;

/* Colors */
$black: $f_gray-950;
$white: $f_gray-0;
$red: #e31d42;
$blue: #525ddc;
$dark-gray: #343a40;
$light-gray: #adb5bd;

$body-bg: $white;
$body-color: $black;

$primary: $f_primary;
$secondary: $f_secondary;
$light: $body-bg;
$dark: $f_footer-bg;

$bg-primary: $f_bg_primary;

$success: $f_success;
$danger: $f_error;

$grays: (
  "100": $f_gray-100,
  "200": $f_gray-200,
  "300": $f_gray-300,
  "400": $f_gray-400,
  "500": $f_gray-500,
  "600": $f_gray-600,
  "700": $f_gray-700,
  "800": $f_gray-800,
  "900": $f_gray-900
);

/* Link colors */
$link-color: $f_link;
$link-decoration: none;
$link-hover-color: $f_link-hover;
$link-hover-decoration: underline;

/* Containers */
$container-max-widths: (
  sm: $f_container-sm,
  md: $f_container-md,
  lg: $f_container-lg,
  xl: $f_container-xl
); //default bootstrap container

/* custom containers */
$container-medium: 1240px;
$container-small: 720px;

/* navbar icon (hamburger) */
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$light-gray}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$light-gray}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

/* Buttons */
$btn-border-radius:    $f_btn-border-radius;
$btn-border-radius-lg: 50px;
$btn-border-radius-sm: $f_btn-small-border-radius;

$btn-padding-y: $f_btn-padding-y;
$btn-padding-x: $f_btn-padding-x; 
$btn-line-height: 25px;

/* Ratios */
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "8x5": calc(5 / 8 * 100%),
  "2x1": calc(1 / 2 * 100%),
  "5x4": calc(4 / 5 * 100%),
  "3x4": calc(4 / 3 * 100%),
  "9x16": calc(16 / 9 * 100%),
  "5x8": calc(8 / 5 * 100%),
  "1x2": calc(2 / 1 * 100%),
  "4x5": calc(5 / 4 * 100%)
);

/* Spacers */
$grid-gutter-width: 2rem;

$spacer: 1rem;
$spacers: (
  0: $f_spacer-0,
  1: $f_spacer-1,
  2: $f_spacer-2,
  3: $f_spacer-3,
  4: $f_spacer-4,
  5: $f_spacer-5,
  6: $f_spacer-6,
  7: $f_spacer-7,
  8: $f_spacer-8,
  9: $f_spacer-9,
  10: $f_spacer-10,
  11: $f_spacer-11,
  12: $f_spacer-12,
  13: $f_spacer-13,
  14: $f_spacer-14,
  15: $f_spacer-15,
  16: $f_spacer-16,
  17: $f_spacer-17,
  18: $f_spacer-18,
  19: $f_spacer-19,
  20: $f_spacer-20,
);

/* Breadcrumbs */
$breadcrumb-font-size: $f_breadcrumbs-d-size;

/* Border raidus */
$border-width: $f_border-width;
$border-radius: $f_border-radius;
$border-radius-sm: $f_border-radius-sm;
$border-radius-lg: $f_border-radius-lg;
$border-radius-xl: $f_border-radius-xl;
$border-radius-circle: $f_border-radius-circle;

/* Button */
$btn-border-radius: $f_btn-border-radius;
$btn-border-radius-sm: $f_btn-small-border-radius;
$btn-padding-x-lg: $f_btn-padding-x;
$btn-padding-y-lg: $f_btn-padding-y;
$btn-padding-x-sm: $f_btn-small-padding-x;
$btn-padding-y-sm: $f_btn-small-padding-y;

$btn-font-size: $f_cta-btn-m-size;
$btn-line-height: $f_cta-btn-m-line-height;
$btn-font-size-sm: $f_cta-btn-s-size;
$btn-font-weight: 700;


/* Tables */
$table-striped-bg: $f_tabel-row-even-color;
$table-bg: $f_table-row-odd-color;
$table-color: $f_table-color;
$table-th-font-weight: $f_table-th-font-weight;


/* Navbar */
$nav-link-font-size: $f_nav-link-font-size;
$nav-link-font-weight: $f_nav-link-font-weight;
$nav-link-color: $white;
$navbar-light-color: $f_navbar-light-color;
$navbar-padding-x: $f_navbar-padding-x;
$navbar-padding-y: $f_navbar-padding-y;
$navbar-nav-link-padding-x: $f_navbar-nav-link-padding-x;
$navbar-brand-padding-y: $f_navbar-brand-padding-y;
$navbar-brand-margin-end: $f_navbar-brand-margin-end;
$navbar-toggler-focus-width: none;

/* Badge */
$badge-border-radius: $f_badge-border-radius;

/* Dropdown */
$dropdown-min-width: 320px;
$dropdown-spacer: 0;
$dropdown-padding-y: 0;
$dropdown-item-padding-x: 32px;
$dropdown-item-padding-y: 16px;
$dropdown-border-radius: 0;
$dropdown-border-width: 0;
$dropdown-link-color: $f_base-color;
$dropdown-link-hover-bg: none;
$dropdown-link-active-bg: none;
$dropdown-link-active-color: $f_base-color;

$accordion-padding-y:5rem;
$accordion-padding-x:5rem;

/* Tabs */
$nav-tabs-border-color: $f_border-color-primary;
$nav-tabs-border-width: 3px;
$nav-tabs-link-active-border-color: $f_primary;

/* Cards */
$card-spacer-y: $f_card-spacer-y;
$card-spacer-x: $f_card-spacer-x;
$card-title-color: $f_card-title-color;
$card-subtitle-color: $f_card-subtitle-color;
$card-box-shadow: $f_card-box-shadow;
$card-border-radius: $f_card-border-radius;
$card-title-spacer-y: $f_card-title-spacer-y;
$card-border-color: $f_card-bg;

/* Bages */
$badge-font-size: $f_badge-font-size;
$badge-font-weight: $f_badge-font-weight;
$badge-padding-y: $f_badge-padding-y;
$badge-padding-x: $f_badge-padding-x;
$badge-border-radius: $f_badge-border-radius;