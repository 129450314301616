.wkb-section {
  position: relative;

  &.bg-light {
    background-color: $f_bg-secondary !important;
  }

  &.bg-dark {
    color: $white;
    background-color: $f_secondary !important;
  }

  &.style-dark {
    color: $white;
  }

  > .bg-image {
    @include fill-container;
    background-size: cover;
    background-position: center;
  }

  &.parallax {
    overflow: hidden;
    
    .bg-image {
      background-attachment: fixed;
    }
  }

  .section-image {
    max-width: 50%;
    position: absolute;
    z-index: 2;

    &.posx-left { left: 0; }
    &.posx-center { left: 50%; transform: translateX(-50%); }
    &.posx-right { right: 0; }

    &.posy-top { top: 0; }
    &.posy-center { top: 50%; transform: translateY(-50%); }
    &.posy-bottom { bottom: 0; }
  }

  .wkb-modules {
    position: relative;
    z-index: 3;
  }
}