article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  header {
    img {
      margin-bottom: 10px;
      height: 100%;
    }
  }

  .post-content {
    margin-bottom: 20px;
  }

  .entry-content,
  footer {
    width: 100%;
  }
}


