/**
* overide woocomerce layout
*/

.woocommerce-billing-fields .form-row, 
.woocommerce-shipping-fields .form-row,
.woocommerce form .form-row {
  display: block;
}

.woocommerce .col2-set .col-1, 
.woocommerce-page .col2-set .col-1,
.woocommerce .col2-set .col-2, 
.woocommerce-page .col2-set .col-2 {
  max-width: unset;
  padding: 0;
}

/**
* overide woocomerce buttons
*/
.woocommerce #respond input#submit, 
.woocommerce a.button, 
.woocommerce button.button, 
.woocommerce input.button {
  @extend .btn;
  @extend .btn-primary;
  font-weight: normal;
}

.woocommerce #respond input#submit.alt, 
.woocommerce a.button.alt, 
.woocommerce button.button.alt, 
.woocommerce input.button.alt {
  @extend .btn;
  @extend .btn-secondary;
  font-weight: normal;
}

.woocommerce #respond input#submit.disabled, 
.woocommerce #respond input#submit:disabled, 
.woocommerce #respond input#submit:disabled[disabled], 
.woocommerce a.button.disabled, 
.woocommerce a.button:disabled, 
.woocommerce a.button:disabled[disabled], 
.woocommerce button.button.disabled,
.woocommerce button.button:disabled, 
.woocommerce button.button:disabled[disabled], 
.woocommerce input.button.disabled, 
.woocommerce input.button:disabled, 
.woocommerce input.button:disabled[disabled] {
  color: $white;
}

/**
* Infos message
*/
.woocommerce-error, 
.woocommerce-info, 
.woocommerce-message {
  background-color: gray("100");
  border-top-color: $primary;
}

.woocommerce-info {
  border-top-color: theme-color("info");

  &:before {
    color: theme-color("info");
  }
}

.woocommerce-message {
  border-top-color: theme-color("success");

  &:before {
    color: theme-color("success");
  }
}

.woocommerce-error {
  border-top-color: theme-color("danger");

  &:before {
    color: theme-color("danger");
  }
}

.woocommerce-store-notice, p.demo_store {
  background-color: $primary;
}

/**
* Price
*/
.woocommerce div.product p.price, 
.woocommerce div.product span.price {
  color: $dark;
}

.woocommerce ul.products li.product .price {
  color: $dark;
}

/**
* Forms
*/
.woocommerce form .form-row input.input-text, 
.woocommerce form .form-row textarea {
  @extend .form-control;
}

.woocommerce form .form-row select {
  @extend .form-select;
}

/**
* Product card
*/
.woocommerce ul.products li.product .woocommerce-loop-category__title, 
.woocommerce ul.products li.product .woocommerce-loop-product__title, 
.woocommerce ul.products li.product h3 {
  color: $body-color;
}

.woocommerce ul.products li.product .price ins,
.woocommerce div.product p.price ins, 
.woocommerce div.product span.price ins {
  text-decoration: none;
  display: block;
}

.woocommerce span.onsale {
  background-color: $primary;
  padding: 0;
}

.woocommerce div.product .product_title {
  font-size: $h3-font-size;
}

.woocommerce a.added_to_cart {
  margin-left: 10px;
}

/**
* Single product
*/
.related.products {
  clear: both;
}

.related.products h2 {
  font-size: $h4-font-size;
  margin-bottom: 30px;
}

.woocommerce div.product div.images .flex-control-thumbs {
  margin-top: 20px;
}

.woocommerce .quantity .qty {
  @extend .form-control;
  width: 60px;
  padding-right: 2px;
}


/**
* Cart
*/
#add_payment_method table.cart td.actions .coupon .input-text, 
.woocommerce-cart table.cart td.actions .coupon .input-text, 
.woocommerce-checkout table.cart td.actions .coupon .input-text {
  width: 140px;
  @extend .form-control;
  
}

.woocommerce table.shop_table {
  border: 0;
  border-collapse: collapse;

  th {
    background-color: gray("200");
  }

  tbody td:not(.actions) {
    background-color: gray("100");
    border: 0;
  }
}

/**
* Checkout
*/
.woocommerce table.shop_table tbody th, 
.woocommerce table.shop_table tfoot td, 
.woocommerce table.shop_table tfoot th {
  border: 0;
}

#add_payment_method #payment, 
.woocommerce-cart #payment, .woocommerce-checkout #payment {
  border-radius: 0;
  background-color: gray("200");
}

#add_payment_method #payment div.payment_box, 
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box {
  background-color: gray("300");
}

#add_payment_method #payment div.payment_box::before, 
.woocommerce-cart #payment div.payment_box::before, 
.woocommerce-checkout #payment div.payment_box::before {
  border-bottom-color: gray("300");
}

body.woocommerce-checkout h3 {
  font-size: $h4-font-size;
}

#customer_details {
  margin-bottom: 30px;
}

/**
* Order recieved
*/
.woocommerce ul.order_details {
  padding: 0;
  background-color: gray("100");
  
  li {
    width: 100%;
    padding: 10px 20px;
    border-bottom: 1px solid gray("200");
    border-right: 0;
  }
}

body.woocommerce-checkout h2 {
  font-size: $h4-font-size;
}

.woocommerce .woocommerce-customer-details address {
  border: 0;
  border-radius: 0;
  background-color: gray("100");
  font-size: 14px;
}

/**
* My account
*/
.woocommerce-MyAccount-navigation {
  
  ul {
    padding: 0;
    list-style: none;
  }

  li {
    border-bottom: 1px solid gray("200");

    &:last-child {
      border-bottom: 0;
    }

    a {
      color: $body-color;
      display: block;
      padding: 10px;
      background-color: gray("100");

      &:hover {
        background-color: gray("200");
        text-decoration: none;
      }
    }
  }
}

.woocommerce-account {
  h3 {
    font-size: $h4-font-size;
  }

  h2 {
    font-size: $h3-font-size;
  }

  address {
    font-size: 14px;
  }
}

.woocommerce form.checkout_coupon, 
.woocommerce form.login, 
.woocommerce form.register {
  background-color: gray("100");
  border: 0;
  border-radius: 0;
}

.myaccount-link {
    padding-left: 25px;
    line-height: 100%;
    font-size: 20px;
  }

.site-header-cart {
  list-style: none;
  margin-bottom: 0;
  padding-left: 25px;
  line-height: 100%;

  &:hover {
    
    .widget_shopping_cart {
      display: block;
    }
  }

  .cart-contents {
    font-size: 20px;
    position: relative;

    .count {
      position: absolute;
      border-radius: 50%;
      background-color: $bg-primary;
      font-size: 9px;
      width: 15px;
      height: 15px;
      line-height: 15px;
      text-align: center;
      top: -3px;
      right: -7px;
    }
  }

  .widget_shopping_cart {
    position: absolute;
    top: 0;
    width: 250px;
    padding: 2rem 1rem 1rem 1rem;
    background: $white;
    color: $black;
    display: none;
    right: 0;
    font-size: 14px;

    .mini_cart_item {
      margin-bottom: 10px;
    }
  }
}

/**
* Cart widget
*/
.woocommerce .widget_shopping_cart {
  box-shadow: 0px 0px 5px gray("200");

  .button {
    display: block;
    font-size: 14px;
    
  }
}

.woocommerce a.remove {
  color: theme-color("danger") !important;

  &:hover {
    background: theme-color("danger") !important;
  }
}

.woocommerce .widget_shopping_cart .cart_list li, 
.woocommerce.widget_shopping_cart .cart_list li {
  font-size: 14px;
}