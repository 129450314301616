.wkb-map {
  position: relative;
  background-color: $f_primary-superlight;

  .map {
    position: relative;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .image-map-container img {
    position: relative;
    z-index: 1;
    width: 100%;
  }

  .location {
    position: absolute;
    z-index: 2;
    display: none;

    &.active {
      display: block;
    }
  }

  .location-inner {
    position: relative;
  }

  .location-data {
    position: absolute;
    left: 50%;
    top: -100px;
    transform: translateX(-50%);
    box-shadow: -2px -2px 0px 0px $primary;
    color: $primary;
    padding: map-get($spacers, 7);
    box-sizing: border-box;
    background-color: $f_primary-superlight;
    min-width: 140px;
    text-align: center;
    font-size: 20px;
  }

  .point-container,
  .center-div {
    position: absolute;
    left: 71%;
    top: 52%;
    z-index: 2;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;

    line {
      stroke-width:2px;
      stroke:red;
    }
  }

  .point-container {
    .inner {
      position: relative;
      width: $f_spacer-14;
      height: $f_spacer-14;
      margin-left: -50%;
      margin-top: -50%;

      div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        transition: all 1s ease-in-out;
      }
      
      div:nth-child(1) {
        width: 15%;
        height: 15%;
        background-color: white;
        z-index: 5;
      }
  
      div:nth-child(2) {
        width: 56.25%;
        height: 56.25%;
        background-color: $f_gray-600;
        z-index: 4;
      }
  
      div:nth-child(3) {
        width: 70%;
        height: 70%;
        border: 1px solid $f_gray-600;
        z-index: 3;
      }
  
      div:nth-child(4) {
        width: 85%;
        height: 85%;
        border: 1px solid $f_gray-600;
        z-index: 3;
      }
  
      div:nth-child(5) {
        width: 100%;
        height: 100%;
        border: 1px solid $f_gray-600;
        z-index: 3;
      }
    }

    &.small .inner {
      div:nth-child(3),
      div:nth-child(4),
      div:nth-child(5) {
        width: 56.25%;
        height: 56.25%;
        border-color: white;
      }
    }
  }

  .content-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-width: map-get($container-max-widths, xl);
    width: 100%;
    padding-top: $spacer;
    padding-bottom: $spacer;

    @media (max-width: 1440px) {
      padding-left: 100px !important;
    }

    @include media-breakpoint-down(md) {
      padding-left: 50px !important;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 0 !important;
    }

    ul {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      list-style-type: none;
      padding-left: 5px;
    }

    .location-trigger {
      display: inline-block;
      position: relative;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: map-get($spacers, 7);
      padding-left: 25px;
      padding-right: 5px;

      &:hover {
        cursor: default;

        &:after {
          display: block;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:after {
        content: '';
        display: none;
        width: 110%;
        height: 3px;
        background-color: black;
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
      }

      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-top: 1px solid black;
        border-right: 1px solid black;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }

    @include media-breakpoint-up(md) {
      padding-left: $spacer;
      padding-right: $spacer;
    }

    @include media-breakpoint-down(md) {
      position: relative;
      left: initial;
      top: initial;
      transform: none;
    }
  }
}

.leader-line {
  z-index: 7;
}