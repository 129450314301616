.wkb-tabs {
  .nav-tabs {
    border-bottom: none;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(sm) {
      flex-wrap: nowrap;
      flex-direction: row;
    }
  }
  .nav-item {
    width: 100%;
    max-width: 192px;
    background-color: $bg-primary;
    border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
    @include media-breakpoint-up(sm) {
      max-width: auto;
    }
  }
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: map-get($spacers, 4);
    border-width: 0px 0px 3px 0px;
    border-radius: 0;
    padding: $f_spacer-7 $f_spacer-8;
    color: $f_base-color;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    width: 100%;

    &.active {
      background-color: transparent;
    }
  }
}