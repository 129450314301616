.card {
  overflow: hidden;
  flex: 1;
  width: 100%;
  border: $border-radius-lg;
  .card-categories {
    display: flex;
    gap: 8px;
  }

  .badge {
    background-color: $primary !important;
    color: $white;
    padding: map-get($spacers, 3) map-get($spacers, 4);
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
  }

  .card-image {
    height: 234px;
  }

  .card-body {
    padding-bottom: 62px;
    background-color: $f_body-bg;
    color: $card-title-color;
  }

  .btn {
    // position: absolute;
    // left: map-get($spacers, 7);
    // bottom: map-get($spacers, 7);

    // &.btn-full {
    //   right: map-get($spacers, 7);
    //   width: calc(100% - 16px);
    // }
  }

  img {
    height: 234px;
    object-fit: cover;
  }
}