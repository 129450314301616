/** Search form */
.search-form {
  //@extend .form-inline;
  .form-inline {
    display: flex;
    gap: 16px;
    max-width: 500px;
  }
}

.search-form label {
  //@extend .form-group;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


/* Ninja Forms */
.nf-form-cont {
  .nf-form-content {
    max-width: 100%;
    padding: 0;
  }

  .nf-before-form-content {
    margin-bottom: 20px;
  }

  .checkbox-container .nf-field-label label {
    font-weight: normal;
  }


  .checkbox-container.label-right .field-wrap {
    flex-wrap: wrap;
  }

  .checkbox-container .nf-field-description {
    margin-top: 10px;
  }

  .nf-form-content button, 
  .nf-form-content input[type=button], 
  .nf-form-content input[type=submit] {
    @extend .btn;
    @extend .btn-primary;
    height: auto;
  }

  // .single-checkbox {
  //   .checkbox-wrap {
  //     display: flex;
  //     flex-wrap: nowrap;
  //   }
  //   .nf-field-element{
  //     display: none;
  //   }
  // }

  .nf-form-content input[type=submit]:disabled {
    color: white;
  }

  .checkbox-wrap .nf-field-label label:after,
  .listcheckbox-wrap .nf-field-element label:after {
    border-color: $f_gray-600;
    background-color: white;
    width: 16px;
    height: 16px;
  }

  .checkbox-wrap .nf-field-element label:before, 
  .checkbox-wrap .nf-field-label label:before, 
  .listcheckbox-wrap .nf-field-element label:before, 
  .listcheckbox-wrap .nf-field-label label:before {
    content: '';
  }

  .checkbox-wrap .nf-field-element label.nf-checked-label:before, 
  .checkbox-wrap .nf-field-label label.nf-checked-label:before, 
  .listcheckbox-wrap .nf-field-element label.nf-checked-label:before, 
  .listcheckbox-wrap .nf-field-label label.nf-checked-label:before {
    content: '';
    display: block;
    width: 6px;
    height: 10px;
    border-right: 2px solid $primary;
    border-bottom: 2px solid $primary;
    transform: rotate(35deg);
    left: -23px;
    top: 5px;
    transition: none;
  }

  .checkbox-container.label-right label:after {
    top: 3px;
  }

  .listradio-wrap .nf-field-element label:after {
    border: 1px solid $f_gray-600;
    background-color: white;
    width: 16px;
    height: 16px;
  }

  .listradio-wrap .nf-field-element label.nf-checked-label:after {
    border-color: $f_gray-600;
  }

  .listradio-wrap .nf-field-element label.nf-checked-label:before {
    background: $primary;
    height: 8px;
    width: 8px;
    left: -26px;
    top: 5px;
  }

  .listradio-wrap label {
    font-size: 14px;
  }

  .checkbox-wrap .nf-field-element label, 
  .checkbox-wrap .nf-field-label label, 
  .listcheckbox-wrap .nf-field-element label, 
  .listcheckbox-wrap .nf-field-label label {
    font-size: 14px;
  }

  input[type=checkbox]:focus+label:after, 
  input[type=radio]:focus+label:after {
    box-shadow: none;
  }

  .checkbox-wrap .nf-field-label label.nf-checked-label:before {
    content: '';

  }
}

.file_upload-container .field-wrap.file_upload-wrap .nf-field-element .nf-fu-fileinput-button {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  width: 100% !important;
  margin-bottom: 8px !important;

  &:hover {
    color: #000 !important;
  }
}

.nf-fu-button-cancel {
  display: none !important;
}