.btn {
  color: $white;

  &-primary:hover,
  &-primary:active,
  &-primary:focus {
    color: $white !important;
    background-color: $f_primary-dark;
    border-color: $f_primary-dark;
  }

  // @include media-breakpoint-down(sm) {
  //   padding: $btn-padding-y-sm $btn-padding-x-sm;
  //   font-size: $btn-font-size-sm;
  //   line-height: $f_cta-btn-s-line-height;
  // }
}

.btn-small {
  padding: 8px 16px;
}

.wkb-button {
  .btn {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
