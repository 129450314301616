body > header {
  background: $secondary;
  transition: all 0.2s ease-in-out;

  // &.has-banner {
    position: fixed;
    z-index: 10;
    width: 100%;
    top: 0;
    padding-left: 100px;
    padding-right: 100px;

    @include media-breakpoint-down(lg) {
      padding-left: 0px;
      padding-right: 0px;
    }
  // }

  // &.hide {
  //   top: -100px;
  // }

  .nav-link {
    color: $white;

    &:focus,
    &:hover {
      color: $f_primary-chroma-plus;
    }

    &:after {
      display: none !important;
    }
  }

  .navbar-inner a.show.dropdown-toggle {
    color: $f_primary-chroma-plus !important;
    background-color: #383E42;
  }

  .navbar-nav > li > a {
    height: 100px;
    display: flex;
    align-items: center;
  }

  .navbar-inner .dropdown-menu .dropdown-item:hover {
    background-color: $gray-300;
  }

  .icon-search:before {
    background-color: white;
  }

  .navbar.navbar-expand-lg {
    @include media-breakpoint-down(lg) {
      padding-right: 16px;
    }
  }

  .navbar-inner {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    .dropdown-toggle::after {
      border: solid $primary;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      margin-left: 16px;
      transition: .3s;

      @include media-breakpoint-down(sm) {
        right: 5px;
      }
    }

    .show.dropdown-toggle::after {
      transform: rotate(225deg);
      transform-origin: 60% 70%;
    }

    .show.dropdown-toggle {
      color: $gray-900 !important;

      &::after {
        border-color: $primary;
      }
    }

    .dropdown-menu {
      .dropdown-toggle::after {
        right: 32px;
        position: absolute;
        transform: rotate(-45deg);
        transform-origin: 0 0;
        top: 27px;

        @include media-breakpoint-down(lg) {
          right: 27px;
          position: absolute;
          transform: rotate(45deg);
          top: 17px;
        }

        @include media-breakpoint-down(sm) {
          right: 0px;
        }
      }

      @include media-breakpoint-down(lg) {
        .show.dropdown-toggle::after {
          transform: rotate(225deg);
          transform-origin: 30% 80%
        }
      }
    }

    .navbar-toggler {
      border: none;
    }

    .dropdown-menu {

      @include media-breakpoint-down(lg) {
        margin-top: 0px;
      }

      .dropdown-item {
        font-weight: 700;
        background-color: #383E42!important;
        color: white!important;

        @include media-breakpoint-down(lg) {
          padding-left: 0px;
        }
      }
    }
  }

  .navbar-collapse > ul > li {
    &:last-child a {
      padding-right: 0!important;
    }
  }

  @include media-breakpoint-down(lg) {
    nav {
      max-width: 100% !important;
    }
    .navbar-toggler {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;

      span.icon:before {
        background-color: white;

        &:hover {
          background-color: white !important;
        }
      }
    }

    .nav-link {
      color: $white;
    }

    .navbar-nav > li > a {
      height: 54px;
    }

    .navbar-collapse {
      position: absolute;
      left: 0;
      background-color: #383E42;
      top: 132px;
      width: 100%;
      padding: 0 1rem;

      @include media-breakpoint-down(sm) {
        top: 85px !important;
      }
    }

    .dropdown-toggle {
      &::after {
        right: 32px;
        position: absolute;
      }
    }
  }

  .header-tools {
    display: flex;
    align-items: center;
    font-size: 20px;

    .widget_polylang {
      margin-left: 20px;
    }

    .select2-container--default {
      .select2-selection--single {
        background-color: transparent;
        border: none;
        border-radius: 0;
      }

      .select2-selection__rendered {
        padding-right: 28px;
        color: white;
      }

      .select2-selection__arrow b {
        border-width: 0 2px 2px 0;
        border-color: white;
        border-style: solid;
        padding: 4px;
        transform: rotate(45deg);
        top: 7px;
        margin-left: 6px;
        left: 0;
        transition: .3s;
      }

      &.select2-container--open {
        .select2-selection__arrow b {
          transform: rotate(225deg);
          border-width: 0 2px 2px 0;
          border-color: white;
          top: 11px;
        }
      }
    }

    .icon-search:hover:before {
      background-color: $f_primary-chroma-plus !important;
    }

    .select2-results__option {
      padding: 6px 15px;
    }
  }

  //search
  .search-form {
    position: relative;

    form {
      position: absolute;
      transition: all 0.2s ease-out;
      right: 20px;
      width: 0;
      overflow: hidden;
      flex-wrap: nowrap;
    }

    &.active {

      .trigger:before {
        content: "\e913";
      }

      form {
        width: 300px;
      }
    }

    .trigger {
      display: block !important;
      cursor: pointer;
      font-size: 32px;
    }
  }

  @include media-breakpoint-down(lg) {
    // .navbar-expand-xl .navbar-collapse {
    //   position: absolute;
    //   left: 0;
    //   top: 100%;
    //   width: 100%;
    //   background: theme-color('dark');
    // }

    .navbar-brand {
      margin-left: 64px;
    }
  }

  @include media-breakpoint-down(md) {
    // .navbar-expand-lg .navbar-collapse {
    //   position: absolute;
    //   left: 0;
    //   top: 100%;
    //   width: 100%;
    // }
    .navbar-collapse {
      top: 132px;
    }
  }

  @include media-breakpoint-down(sm) {
    .navbar-brand img {
      max-width: 135px;
    }

    .search-form.active form {
      width: 70%;
    }

    .myaccount-link {
      padding-left: 0;
    }

    .site-header-cart {
      padding-left: 15px;
    }

    .site-header-cart .widget_shopping_cart {
      transform: translateX(-50%);
      left: 50%;
      right: auto;
    }
  }
}

body.open-menu .top-bar {
  display: block;
}

.top-bar {
  position: absolute;
  z-index: 9;
  left: 0;
  top: 99px;
  
  color: $secondary;
  width: 100%;

  @include media-breakpoint-down(lg) {
    display: none;
    .top-bar-inner {
      max-width: 100% !important;
    }
  }


  @include media-breakpoint-down(sm) {
    top: 52px;

    section {
      padding: 8px 8px 8px 0 !important;    
    }
  }

  section {
    position: relative;
    display: inline-block;
    background-color: $f_gray-300;
    padding: map-get($spacers, 4) map-get($spacers, 14) map-get($spacers, 4) 0;

    @include media-breakpoint-down(lg) {
      position: fixed;
    }
    
    &:before {
      content: '';
      display: block;
      width: 3000px;
      height: 100%;
      position: absolute;
      right: 100%;
      top: 0;
      background-color: $f_gray-300;
    }
  }

  a {
    color: $secondary;
    text-decoration: none;
  }

  ul {
    display: flex;
    gap: 24px;
    list-style: none;
    margin-bottom: 0;
    color: $secondary;
    padding: 0;
    font-size: 12px;

    @include media-breakpoint-down(md) {
      gap: 16px;
    }
  }
}

.page-header{
  position: relative;
  margin-bottom: 20px;
  margin-top: 30px;

  h1 {
    display: inline-block;
    background-color: $f_primary-light;
    padding: map-get($spacers, 8) map-get($spacers, 14) map-get($spacers, 8) 0;
    margin-bottom: 0;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 33px;

    h1 {
      padding: map-get($spacers, 5) map-get($spacers, 9) map-get($spacers, 5) 0;

    }
  }

  &:before {
    content: '';
    display: block;
    width: 3000px;
    height: 100%;
    position: absolute;
    right: 100%;
    top: 0;
    background-color: $f_primary-light;
  }
}