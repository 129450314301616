.wkb-image {
  
    a {
      text-decoration: none;
      color: inherit;
      position: relative;
      display: block;
    }
  
    // .content {
    //   text-align: left;
    // }
  
    .image-wrap {
      position: relative;
    }
  
    .ratio .image {
      @include fill-container;
      background-position: center;
      background-size: cover;
    }
    
    .content {
      margin-top: 40px;

      .title {
        margin-bottom: 0;
      }
    }

    &.text-on-image {
      .content {
        position: absolute;
        left: 0;
        top: 0;
        padding: 45px 25px 45px 25px;
        z-index: 3;
  
        .content-inner {
          position: relative;
          z-index: 3;
        }
  
        .img-overlay {
          background: linear-gradient(180deg, rgba($dark,1) 0%, rgba($dark,0.8) 65%, rgba($dark,0) 100%);
  
          &.light {
            background: linear-gradient(180deg, rgba($light,1) 0%, rgba($light,0.8) 65%, rgba($light,0) 100%);
          }
        }
      }
    }
  
    &.text-middle {
      &.text-on-image .content {
        top: 50%;
        transform: translateY(-50%);
  
        .img-overlay {
          background: linear-gradient(180deg, rgba($dark,0) 0%, rgba($dark,0.87718837535014) 20%, rgba($dark,1) 50%, rgba($dark,0.88) 80%, rgba($dark,0) 100%);
          
          &.light {
            background: linear-gradient(180deg, rgba($light,0) 0%, rgba($light,0.87718837535014) 20%, rgba($light,1) 50%, rgba($light,0.88) 80%, rgba($light,0) 100%);
          }
        }
      }
    }
  
    &.text-bottom {
      &.text-on-image .content {
        bottom: 0;
        top: auto;
  
        .img-overlay {
          background: linear-gradient(180deg, rgba($dark,0) 0%, rgba($dark,0.8) 35%, rgba($dark,1) 100%);
  
          &.light {
            background: linear-gradient(180deg, rgba($light,0) 0%, rgba($light,0.8) 35%, rgba($light,1) 100%);
          }
        }
      }
    }
  }
  