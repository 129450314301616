.wkb-video {
  
    &__container {
      position: relative;
    }
  
    &.playing {
      .poster {
        display: none;
      }
    }
  
    .video {
      width: 100%;
    }
  
    .poster {
      @include fill-container;
      background-size: cover;
      background-position: center;
      z-index: 2;
    }
  
    .play-btn {
      @include centerer(both);
      z-index: 4;
      display: flex;
      align-items: center;
      cursor: pointer;
  
      .play-btn-icon {
        background-image: url('../images/play-button.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100px;
        height: 100px;
        display: block;
      }
    }
  
    iframe,
    video {
      background: $black;
    }

    .content {
      margin-top: 10px;
  
      p {
        margin-bottom: 0;
      }
    }
  }